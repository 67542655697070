import React, { useState, useEffect, useRef } from 'react';
import useScreenResize from '../common/useScreenResize';
import { Link } from 'react-router-dom';
import { Timeline } from 'react-twitter-widgets'
// Components
import TitleTextBox from '../common/title_text';
import ImageCard from './about_info_card';
import AboutResearchList from './about_research_list';
import Footer from '../footer/footer';
import AboutNewsList from './about_news_list';
import AboutResearchCardList from './about_research_card_list'
// images
import scheller_cover from '../../images/scheller-cover.jpg';
import vip_image_website from '../../images/vip_image_websie_small.png'
import people_cover from '../../images/people-cover.jpg';
import annual_report from '../../images/annual_report_2024.jpg'
import research_cover from '../../images/research-cover.jpg';
import qcf_cover from '../../images/qcf-cover.jpg';
import Sudheer_Chava from '../../images/Sudheer_Chava.jpg';
import peopleimage from '../../images/people-cover2.png';
import event_cover from '../../images/events.png';

function About() {

    const title = "GT FinTech Lab";
    const description = "Georgia Tech’s Financial Services Innovation Lab is located in the heart of the Technology Square Innovation ecosystem. The FinTech lab is a hub for finance education, research and industry in the Southeast. The lab acts as a platform to connect and bring together faculty and students across Georgia Tech with the financial services industry and FinTech entrepreneurs.";

    const news_header = "Financial Services Innovation Lab and Equifax Create Partnership to Advance Inclusive Fintech Innovation"
    const news_description = "Financial Services Innovation Lab and Equifax have announced a new research partnership designed to develop new ways to reach underserved, underbanked and credit-rebuilding consumers. As part of this relationship, the Financial Services Innovation Lab at Georgia Tech will leverage anonymized Equifax data assets to further its innovative research designed to increase consumer access to credit and Equifax data scientists will provide hands-on data education to speed research time. The research partnership is designed to stimulate growth in fintech entrepreneurship in Atlanta"

    const navbarRef = useRef(null);
    const { isMobile, navbarHeight } = useScreenResize(navbarRef);
    const appliedStyles = isMobile ? mobile_styles : styles;

    return (
        <div>
            <img style={styles.aboutImg} src={scheller_cover} alt="Scheller Cover" />
            <TitleTextBox title={title} description={description} />

            <div style={appliedStyles.infoBox}>
                <Link to='/people'><ImageCard imagef={peopleimage} cap1="People" /></Link>
                <Link to='/research'><ImageCard imagef={research_cover} cap1="Research" /></Link>
                <a href="https://qcf.gatech.edu"><ImageCard imagef={qcf_cover} cap1="QCF Program" /></a>
                <Link to="/eLearning"><ImageCard imagef={vip_image_website} cap1="Projects" /></Link>
            </div>

            {/******  MOBILE VERSION  ******/}
            {isMobile ?
                <div>
                    <div style={appliedStyles.news_events_container}>
                        {/* RESEARCH */}
                        <h6 style={appliedStyles.myTitle}>{"Research"}</h6>
                        <div style={appliedStyles.newsContainer}> <AboutNewsList /> </div>
                        <div style={appliedStyles.myDivider}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="259" height="4" viewBox="0 0 259 4" fill="none">
                                <path d="M0 2H259" stroke="#B3A369" stroke-width="3" />
                            </svg>
                            <Link to='/news'><p style={appliedStyles.dividerText}>{"Click here to view more"}</p></Link>
                        </div>

                        {/* events */}
                        <h6 style={{ ...appliedStyles.myTitle, marginTop: "90px" }}>{"Events"}</h6>
                        <img src={event_cover} style={appliedStyles.eventPicture} />
                        <p style={appliedStyles.eventText}>{"Tokenization - The Next Essential Tool in Investment Management?"}</p>
                        <div style={appliedStyles.myDivider}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="259" height="4" viewBox="0 0 259 4" fill="none">
                                <path d="M0 2H259" stroke="#B3A369" stroke-width="3" />
                            </svg>
                            <Link to='/research'><p style={appliedStyles.dividerText}>{"Click here to view more"}</p></Link>
                        </div>
                    </div>

                    {/* annual report */}
                    <h6 style={{ ...appliedStyles.myTitle, marginTop: "90px" }}>{"Annual Report"}</h6>
                    <div style={{ marginBottom: "90px" }}>
                        <img src={annual_report} style={styles.reportPicture} />
                        <p style={appliedStyles.dividerText}>{"Coming Soon..."}</p>
                    </div>

                    {/* twitter */}
                    <h6 style={appliedStyles.myTitle}>{"Twitter"}</h6>
                    <div style={appliedStyles.twitterContainer}>
                        <Timeline
                            dataSource={{ sourceType: "profile", screenName: "gtfintechlab" }}
                            options={{ width: "330", height: "800" }}
                        />
                    </div>
                    {/* research */}
                    {/* <h6 style={appliedStyles.myTitle}>{"Ongoing Research"}</h6>
                    <div style={appliedStyles.researchContainer}>
                        <AboutResearchCardList />
                    </div> */}

                </div>
                :
                // NORMAL PAGE VERSION
                <div>
                    <div style={styles.midContainer}>
                        <div style={styles.leftContainer}>
                            <h6 style={styles.myTitle}>{"Research"}</h6>
                            <AboutNewsList />
                            <div style={styles.myDivider}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="4" fill="none">
                                    <path d="M0 2H515" stroke="#B3A369" strokeWidth="3" />
                                </svg>
                                <Link to='/research'><p style={styles.dividerText}>{"Click here to view more"}</p></Link>
                            </div>
                        </div>

                        <div style={styles.rightContainer}>
                            <h6 style={styles.myTitle}>{"Annual Report"}</h6>
                            <div>
                                <img src={annual_report} style={styles.reportPicture} />
                                <p style={appliedStyles.dividerText}>{"Coming Soon..."}</p>
                            </div>

                            {/* events */}
                            <h6 style={styles.myTitle}>{"Events"}</h6>
                            <div id="events">
                                <img src={event_cover} style={styles.eventPicture} />
                                <p style={styles.eventText}>{"Tokenization - The Next Essential Tool in Investment Management?"}</p>
                                <div style={styles.myDivider_short}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="93" height="4" viewBox="0 0 93 4" fill="none">
                                        <path d="M0 2L93 2" stroke="#B3A369" stroke-width="3" />
                                    </svg>
                                    <Link to='/events'><p style={styles.dividerText}>{"Click here to view more"}</p></Link>
                                </div>
                            </div>{/* events */}

                            <h6 style={styles.myTitle}>{"Twitter"}</h6>
                            <Timeline
                                dataSource={{ sourceType: "profile", screenName: "gtfintechlab" }}
                                options={{ width: "330", height: "800" }}
                            />
                        </div>
                    </div>
                    {/* research */}
                    {/* <TitleTextBox title={"Ongoing Research"} />
                    <div style={appliedStyles.researchContainer}>
                        <AboutResearchCardList />
                    </div> */}
                </div>
            }
        </div>
    );
}

/* styles */
const styles = {
    aboutImg: {
        width: "100%",
        height: "100%",
        flexShrink: 0,
        // objectFit:"fit"
    },

    myTitle: {
        fontFamily: "'Roboto', sans-serif",
        color: "#54585A",
        textAlign: "center",
        fontSize: "35px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        height: "44px",
        flexShrink: 0,
    },

    infoBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: "87px 137px 0",
        gap: "89px",
    },

    midContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        gap: "92px",
        padding: "40px 140px 140px 140px",
    },

    leftContainer: {
        display: "flex",
        flexDirection: "column",
        flexShrink: 0,
        gap: "35px",
        paddingTop: "50px",
    },

    myDivider: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexShrink: 0,
        gap: "35px",
        float: "right",
        flexDirection: 'row',
    },

    dividerText: {
        width: "230px",
        flexShrink: 0,
        lineHeight: "normal",
        color: "#B3A369",
        fontFamily: "'Roboto', sans-serif",
        fontWeight: "500px",
        fontSize: "20px",
        margin: "auto",
        paddingTop: "0px",
    },

    rightContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "39px",
        float: "left",
        //   padding: "50px 140px 0px 0px"
        paddingTop: "50px",
    },

    reportPicture: {
        width: "320px",
        flexShrink: 0,
        paddingBottom: "5px",
    },

    eventPicture: {
        width: "320px",
        height: "216px",
        flexShrink: 0,
    },

    eventText: {
        width: "328px",
        height: "40px",
        flexShrink: 0,
        color: "#54585A",
        textAlign: "center",
        fontFamily: "'Roboto', sans-serif",
        fontSize: "20px",
        fontStyle: "normal",
        lineHeight: "normal",
        paddingTop: "9px",
    },

    myDivider_short: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "330px",
        gap: "39px",
        float: "left",
        flexDirection: 'row',
        paddingTop: "15px",
    },

    researchContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px 123px 135px",
        gap: "65px",
    }
};

/***** MOBILE ******/
const mobile_styles = {
    aboutImg: {
        width: "100%",
        height: "380px",
        flexShrink: 0,
        padding: 0,
    },
    infoBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "90px",
        gap: "50px",
    },

    news_events_container: {
        paddingLeft: "10px",
        paddingRight: "10px",
    },

    myTitle: {
        fontFamily: "'Roboto', sans-serif",
        color: "#54585A",
        textAlign: "center",
        fontSize: "35px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        paddingBottom: "30px",
    },
    myDivider: {
        display: "flex",
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: "center",
        padding: "20px 30px 0px",
    },
    dividerText: {
        flexShrink: 0,
        width: "250px",
        lineHeight: "normal",
        color: "#B3A369",
        fontFamily: "'Roboto', sans-serif",
        fontWeight: "500px",
        fontSize: "20px",
        margin: "auto",
    },

    newsContainer: {
        height: "315px",
        padding: "0px 30px 30px",
    },

    eventPicture: {
        width: "600px",
        height: "400px",
        flexShrink: 0,
        margin: "auto",
    },
    eventText: {
        height: "40px",
        flexShrink: 0,
        margin: "auto",
        color: "#54585A",
        textAlign: "center",
        fontFamily: "'Roboto', sans-serif",
        fontSize: "15px",
        fontStyle: "normal",
        lineHeight: "normal",
        paddingTop: "20px",
        paddingBottom: "50px",
    },
    reportPicture: {
        width: "320px",
        flexShrink: 0,
        margin: "auto",
        paddingBottom: '90px',
    },
    twitterContainer: {
        width: "330px",
        display: "flex",
        margin: "auto",
        paddingBottom: "90px",
    },
    researchContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "45px",
        paddingBottom: "135px",
    },
};

export default About;
