import React from 'react';
import 'tachyons';

const Peoplecard = ({website, imagef, name, role, company, type}) => {

    const ugms_alumni_tag = "Undergrad_Alumni"

    return(
        <div className = ' dib br3 pa2 ma3 grow bw2'>
                <a target="_blank" rel="noopener noreferrer" href = {website}>
                    {type !== ugms_alumni_tag && <img className = "cardimgstyling" src = {imagef} alt = "" />}
                </a>
                
                <div className = "cardtextstyling">
                    <h5 className={type !== ugms_alumni_tag ? `CL_h5_new`: `CL_h5_new_alum`}>{name}</h5>   
                    {/* <h6 className={type !== ugms_alumni_tag ? `CL_h6_new`: `CL_h6_new_alum`}>{role}</h6> */}
                    <h6 className={type !== ugms_alumni_tag ? `CL_h6_new`: `CL_h6_new_alum`}>{company}</h6>
                </div>     
        </div>
    )
}

export default Peoplecard;